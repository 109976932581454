import request from '@/utils/request'
// import QS from 'qs';
//获取用户登陆token
// export function fetchUserLoginToken(params) {
//     return request({
//         url: '/inside/app/user/login',
//         method: 'post',
//         data: params
//     })
// }
export function login(params={}) {
    return request({
        url: '/Api/publicApi/login',
        method: 'post',
        // data: QS.stringify(params)
        data: params,
    })
}
export function register(params={}) {
    return request({
        url: '/Api/publicApi/register',
        method: 'post',
        // data: QS.stringify(params)
        data: params,
    })
}
export function logout(params={}) {
    return request({
        url: '/Api/UserApi/logout',
        method: 'post',
        // data: QS.stringify(params)
        data: params,
    })
}

