import { EaseChatClient } from '@/IM/initwebsdk'
// import { useLocalStorage } from '@vueuse/core';
import { sortPinyinFriendItem, handlePresence } from '@/utils/handleSomeData'
import _ from 'lodash'
import {getUserFriendList,myGroups} from '@/api/maillist';
import {get_group_info} from '@/api/group'
const Contacts = {
    state: {
    // friendList: useLocalStorage('friendList', {}),
        friendList: {},
        // groupList: useLocalStorage('groupList', {}),
        groupList: {},
        // sortedFriendList: useLocalStorage('sortedFriendList', {}),
        sortedFriendList: {},
        friendBlackList: [],
    },
    mutations: {
        SET_FRIEND_LIST: (state, payload) => {
            state.friendList = _.assign({}, payload)
        },
        // SET_NEW_FRIEND_LIST: (state, payload) => {
        //     state.friendList = _.assign({}, payload)
        // },
        SET_BLACK_LIST: (state, payload) => {
            state.friendBlackList = _.assign([], payload)
        },
        SET_FRIEND_PRESENCE: (state, status) => {
            const friendList = state.friendList
            status.length > 0 &&
        status.forEach((item) => {
            const commonStatus = handlePresence(item)
            if (friendList[commonStatus.uid]) {
                friendList[commonStatus.uid].userStatus = commonStatus
            }
        })
        },
        SET_SORDED_FRIEND_LIST: (state, payload) => {
            state.sortedFriendList = _.assign({}, payload)
        },
        SET_GROUP_LIST: (state, payload) => {
            //init 为初始化获取 replenish 补充群列表（包括补充群详情）
            const { setType, data } = payload
            if (setType === 'init') {
                state.groupList = _.assign({}, data)
            }
            if (setType === 'replenish') {
                const { id, name, disabled,type,groupavatar,owner,stopper} = data
                if (state.groupList[id]) {
                    state.groupList[id].groupDetail = data
                } else {
                    // disabled:"false",
                    // groupid: d.group_emchat_id,
                    // groupname: d.group_name,
                    // groupavatar: d.group_logo,
                    // owner: d.owner,
                    // type: d.type, //string 群内角色，0普通用户，1管理员  2群主
                    // isEstoppel: d.stopper, //string 是否禁言:1正常,2禁言
                    state.groupList[id] = {
                        groupid: id,
                        groupname: name,
                        disabled: disabled,
                        type,
                        groupavatar,
                        owner,
                        isEstoppel:stopper,
                        groupDetail: data,
                    }
                }
                // console.log('>>>>>补充群详情', data)
            }
        },
        //示例优化方向--减少群组详情的调用，转为更新本地群组详情数据
        UPDATE_GROUP_INFOS: (state, payload) => {
            // console.log('>>>>>>开始修改', payload)
            const { groupId, type, params } = payload
            //key(群id)，type（群详情对应要修改的字段）
            if (state.groupList[groupId] && state.groupList[groupId].groupDetail) {
                switch (type) {
                //修改群名
                case 'groupName':
                    {
                        // console.log('>>>>>>进入群组名称修改')
                        state.groupList[groupId].groupDetail.name = params
                    }
                    break
                case 'groupDescription':
                    {
                        state.groupList[groupId].groupDetail.description = params
                    }
                    break
                case 'addAffiliationsCount':
                    {
                        state.groupList[groupId].groupDetail.affiliations_count =
                state.groupList[groupId].groupDetail.affiliations_count + 1
                    }
                    break
                case 'delAffiliationsCount':
                    {
                        state.groupList[groupId].groupDetail.affiliations_count =
                state.groupList[groupId].groupDetail.affiliations_count - 1
                    }
                    break
                default:
                    break
                }
            }
        },
        //示例优化方向--更改本地群组列表群名(或其他状态)
        UPDATE_GROUP_LIST: (state, payload) => {
            const { type, groupId, groupName } = payload
            if (type === 'updateGroupName') {
                // console.log('>>>>>更新本地群组列表群名')
                state.groupList[groupId].groupname = groupName
            }
            if (type === 'deleteFromList') {
                // console.log('>>>>>从本地群组列表中删除某个群')
                state.groupList[groupId] && delete state.groupList[groupId]
            }
        },
    },
    actions: {
    //获取好友列表
        fetchFriendList: async ({ dispatch, commit }) => {
           const { data } = await getUserFriendList();
            //后端返回
            // "alias": "朋友", //备注
            // "user_id": "127", //用户ID
            // "nickname": "朋友", //昵称
            // "user_logo_thumb": "https://liaoxinha.oss-cn-hangzhou.aliyuncs.com/photo/1629680472.jpg", //头像缩略图
            // "user_emchat_name": "20210612_134255_559909", //环信帐号ID
            // "mobile": "15554871153" //手机号
            // 兼容demo写法
            // friendList
                // huanhuan:Object
                //     hxId:"huanhuan"
                //     userStatus:Object
                //     expiry:1681694486
                //     ext:""
                //     lastTime:0
                //     statusDetails:Array[0]
                //     uid:"huanhuan"
                // 3a5fb7c1a3:Object
                //     hxId:"3a5fb7c1a3"
                //     userStatus:Object
                //         expiry:1681694947
                //         ext:""
                //         lastTime:1679102867
                //         statusDetails:Array[1]
                //             0:Object
                //             device:"webim_random_1679102867264"
                //             status:1
                //         uid:"3a5fb7c1a3"
            

            // })
            const mergedFriendList={}
            data.map(d=>{
                mergedFriendList[d.user_emchat_name]={
                      hxId:d.user_emchat_name,
                      avatarurl:d.user_logo_thumb,
                      nickname: d.alias || d.nickname,  //优先使用本人备注好友名称-兼容demo展示
                      nickname_real: d.nickname, //新加 用于个人详情页
                      alias_real: d.alias, //新加 用于个人详情页
                      user_id: d.user_id,
                      userStatus:{
                          expiry:1681694486,
                          ext:"",
                          lastTime:0,
                          statusDetails:[],
                          uid:d.user_emchat_name,
                      }
                  }
              })
            //合并后的好友列表数据进行排序并单独提交处理
            const sortFriendList = sortPinyinFriendItem(mergedFriendList)
            commit('SET_FRIEND_LIST', mergedFriendList)
            commit('SET_SORDED_FRIEND_LIST', sortFriendList)
            return;


            const friendListData = {}
            try {
                //获取好友列表
                const { data } = await EaseChatClient.getContacts()
                data.length > 0 &&
          data.map((item) => (friendListData[item] = { hxId: item }))
                //获取好友列表对应的用户属性
                const friendListWithInfos = await dispatch('getOtherUserInfo', data)
                //合并两对象
                const mergedFriendList = _.merge(friendListData, friendListWithInfos)
                //合并后的好友列表数据进行排序并单独提交处理
                const sortFriendList = sortPinyinFriendItem(mergedFriendList)
                commit('SET_SORDED_FRIEND_LIST', sortFriendList)
                commit('SET_FRIEND_LIST', mergedFriendList)
                //提交之后订阅好友状态
                dispatch('subFriendsPresence', data)
            } catch (error) {
                //异常一般为获取会话异常，直接提交好友列表
                commit('SET_FRIEND_LIST', friendListData)
                commit('SET_SORDED_FRIEND_LIST', sortFriendList)
                //提交之后订阅好友状态
                dispatch('subFriendsPresence', data)
            }
        },

        //获取黑名单列表
        fetchBlackList: async ({ dispatch, commit }, params) => {
            const { data } = await EaseChatClient.getBlocklist()
            data.length > 0 && commit('SET_BLACK_LIST', data)
        },
        //获取他人用户属性
        getOtherUserInfo: async ({ commit }, users) => {
            /**
       * @param {String|Array} users - 用户id
       */

            return new Promise(async (resolve, reject) => {
                let usersInfosObj = {}
                const requestTask = []
                const usersArr = _.chunk([...users], 99) //分拆users 用户属性获取一次不能超过100个
                try {
                    usersArr.length > 0 &&
            usersArr.map((userItem) =>
                requestTask.push(EaseChatClient.fetchUserInfoById(userItem))
            )
                    const result = await Promise.all(requestTask)
                    const usersInfos = _.map(result, 'data')
                    usersInfos.length > 0 &&
            usersInfos.map(
                (item) => (usersInfosObj = Object.assign(usersInfosObj, item))
            )
                    resolve(usersInfosObj)
                } catch (error) {
                    reject(error)
                }
            })
        },
        //订阅好友的在线状态
        subFriendsPresence: async ({ commit }, users) => {
            const requestTask = []
            const usersArr = _.chunk([...users], 5) //分拆users 订阅好友状态一次不能超过100个
            try {
                usersArr.length > 0 &&
          usersArr.map((userItem) =>
              requestTask.push(
                  EaseChatClient.subscribePresence({
                      usernames: userItem,
                      expiry: 30 * 24 * 3600,
                  })
              )
          )
                const resultData = await Promise.all(requestTask)
                const usersPresenceList = _.flattenDeep(_.map(resultData, 'result')) //返回值是个二维数组，flattenDeep处理为一维数组
                const tobeCommitRes =
          usersPresenceList.length > 0 &&
          usersPresenceList.filter((p) => p.uid !== '')
                // console.log('resultData', resultData)
                commit('SET_FRIEND_PRESENCE', tobeCommitRes)
            } catch (error) {
                // console.log('>>>>>>订阅失败', error)
            }
        },
        //取消订阅
        unsubFriendsPresence: async ({ commit }, user) => {
            const option = {
                usernames: [...user],
            }
            EaseChatClient.unsubscribePresence(option).then((res) => {
                // console.log('>>>>>>>成功取消订阅', res)
            })
        },
        //获取群组列表
        fetchGroupList: async ({ commit }, params) => {
            //兼容demo写法  groupList:{}
            // 209039239741445:Object
            //     disabled:"false"
            //     groupid:"209039239741445"
            //     groupname:"四海之内皆兄弟"
            //后端返
            // "group_id": null,
			// "group_name": "群聊",
			// "group_logo": "http://lingliao.huxiukeji.com//Public/default_group_logo.png",
			// "group_emchat_id": null,
			// "count": "0"

            const { data } = await myGroups({is_all: 1});
            const goupListData = {}
            data.forEach(d=>{
                if(d.group_emchat_id){
                    goupListData[d.group_emchat_id]={
                        disabled:"false",
                        groupid: d.group_emchat_id,
                        groupname: d.group_name,
                        groupavatar: d.group_logo,
                        owner: d.owner,
                        // 在群详情内查看--
                        // type: d.type, //string 群内角色，0普通用户，1管理员  2群主 
                        // isEstoppel: d.stopper, //string 是否禁言:1正常,2禁言
                    }
                }
            })
            commit('SET_GROUP_LIST', { setType: 'init', data: goupListData })
            // console.log('>>>>>触发了拉取群组列表更新')
            // demo
            // const res = await EaseChatClient.getJoinedGroups({
            //     // needAffiliations: true,
            //     // needRole: true,
            //     ...params,
            // })
            // const goupListData = _.keyBy(res.data, 'groupid')
          
            // commit('SET_GROUP_LIST', { setType: 'init', data: goupListData })
            // // console.log('>>>>>触发了拉取群组列表更新')
        },
        //获取指定群详情
        getAssignGroupDetail: async ({ dispatch, commit }, goupsId) => {
            //demo写法
            // const options = {
            //     groupId: goupsId, // 群组id
            // }
            // const result = await EaseChatClient.getGroupInfo(options)
            // // // console.log('>>>>>>>群详情获取成功result', result);
            //     result.data &&
            // commit('SET_GROUP_LIST', {
            //     setType: 'replenish',
            //     data: result.data[0],
            // })
            // PC后端写法
            const {data} = await get_group_info({group_emchat_id:goupsId});
            const {head_sculpture, group_about,group_emchat_id,group_user_limit,is_auther,group_name,user_emchat_name,stopper,group_logo,family,group_id} = data;
            const affiliations = [];  //群成员
            let affiliations_count = 0;
            if(head_sculpture && head_sculpture.length>0){
                head_sculpture.forEach(h=>{
                affiliations.push({
                    joined_time:'',
                    member: h.nickname, //以上两兼容环信
                    avatar: h.user_logo_thumb,
                    hId: h.user_emchat_name,
                    userId: h.user_id,
                })
                affiliations_count = head_sculpture.length;
                })
            }
            const groupDetail = {
                allowinvites: false, created: '', custom: "default", description: group_about, disabled: false,
                id: group_emchat_id, maxusers: group_user_limit, membersonly: is_auther === 2 ? true : false,
                mute: true, name: group_name, owner: user_emchat_name, public: true, shieldgroup: false, stopper: stopper,
                affiliations,affiliations_count,

                groupavatar: group_logo,
                type: family, //string 群内角色，0普通用户，1管理员  2群主
                group_id,  //禁言用

            }
            commit('SET_GROUP_LIST', {
                setType: 'replenish',
                data: groupDetail,
            })

        },
    },
}

export default Contacts
