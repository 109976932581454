import axios from 'axios'
import {getToken} from '@/_utils/auth'
import QS from 'qs';
import { ElNotification } from 'element-plus'
// const defaultBaseUrl = '//a1.easemob.com'
const defaultBaseUrl = 'http://lingliao.huxiukeji.com'
// const defaultBaseUrl = 'http://lingchat.huxiukeji.com'
// const defaultBaseUrl = '/'

// create an axios instance
const service = axios.create({
    withCredentials: false,
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // baseURL: `${window.location.protocol}${defaultBaseUrl}`,
    baseURL: defaultBaseUrl,
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000, // request timeout
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    headers: {'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'}
})


// if (process.env.NODE_ENV == 'development') {    
//     // axios.defaults.baseURL = '/'; //如不加/rich/about; 请求时会带上/rich/...--vue.config.js内配
//     axios.defaults.baseURL = defaultBaseUrl; //如不加/rich/about; 请求时会带上/rich/...--vue.config.js内配
// }else if(process.env.NODE_ENV == 'production') {    
// 	axios.defaults.baseURL = defaultBaseUrl;
// }
// request interceptor
service.interceptors.request.use(
    (config) => {
        // do something before request is sent
        let token = getToken();
        if(token){
            config.data={...config.data,token};
        }
        config.data = QS.stringify(config.data)
        return config
    },
    (error) => {
        // do something with request error
        // console.log('request error', error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (response) => {
        const res = response.data
        const code = response.status
        // if the custom code is not 20000, it is judged as an error.
        if (code >= 400) {
            return Promise.reject(new Error(res.desc || 'Error'))
        } else if(res.status !==1 ){
            ElNotification({
                // title: '提示',
                message: res.info ? res.info: '请求失败',
                // center: true,
                type: 'error', //type: 'success',
            })
            return Promise.reject(new Error(res.info || 'Error'))
        }else{
            return res
        }
    },
    (error) => {
        if (error.response) {
            const res = error.response.data // for debug
            if (error.response.status === 401 && res.code !== '001') {
                // console.log('>>>>>无权限')
            }
            if (error.response.status === 403) {
                res.desc = '您没有权限进行查询和操作!'
            }
            return Promise.reject(res.desc || error)
        }
        return Promise.reject(error)
    }
)

export default service
