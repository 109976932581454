import request from '@/utils/request'
// import QS from 'qs';
export function post_update(params) {
    return request({
        url: '/Api/Group/post_update',
        method: 'post',
        data: params
    })
}
export function post_update_group(params) {
    return request({
        url: '/Api/Group/post_update_group',
        method: 'post',
        data: params
    })
}
export function post_delete(params) {
    return request({
        url: '/Api/Group/post_delete',
        method: 'post',
        data: params
    })
}
export function quitGroup(params) {
    return request({
        url: '/Api/Group/quitGroup',
        method: 'post',
        data: params
    })
}
export function get_group_info(params) {
    return request({
        url: '/Api/Group/get_group_info',
        method: 'post',
        data: params
    })
}

export function getGroupMemberList(params) {
    return request({
        url: '/Api/Group/getGroupMemberList',
        method: 'post',
        data: params
    })
}

export function totalGag(params) {
    return request({
        url: '/Api/Group/totalGag',
        method: 'post',
        data: params
    })
}