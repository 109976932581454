<script setup>
import {computed,ref} from 'vue'
import { useStore } from 'vuex'
import router from '@/router'
import { EaseChatSDK, EaseChatClient } from '@/IM/initwebsdk'
import { handleSDKErrorNotifi, setMessageKey } from '@/utils/handleSomeData'
import { informType } from '@/constant'
import { usePlayRing } from '@/hooks'
import ring from '@/assets/ring.mp3'
/* callkit */
import EaseCallKit from '@/components/EaseCallKit'
import InviteCallMembers from '@/components/InviteCallMembers'
import { getUserinfo } from '@/api/user'

const store = useStore()
const { isOpenPlayRing, clickRing } = usePlayRing()
EaseChatSDK.logger.disableAll()
/* connect 相关监听 */
EaseChatClient.addEventHandler('connection', {
    onConnected: async () => {
        // // console.log('>>>>>环信连接成功')
        store.commit('CHANGE_LOGIN_STATUS', true)
        if (isOpenPlayRing.value) clickRing()
        fetchLoginUsersInitData()

        // 登录按钮和自动登录 更新个人信息和store
        const {data} =  await getUserinfo()
        const {user_emchat_name,mobile,nickname,user_emchat_password,user_logo,token,gender,user_signature} = data;
        let option = {
              nickname: nickname,
              avatarurl: user_logo,
              gender: gender,
              sign: user_signature,
              ext: JSON.stringify({
                avatar: user_logo,
                nickname: nickname,
                mobile: mobile,
                user_type:0,
              })
          }
        EaseChatClient.updateUserInfo(option).then((res) => {
            // // console.log("更新本人信息",res);
        })  
        store.commit('SET_LOGIN_USER_INFO',{
            // nickname: user_emchat_name,  
            hxId: user_emchat_name,
            avatarurl: user_logo,
            mobile,
            nickname,
            gender,
            user_signature
        })
        router.replace('/chat')
    },
    onDisconnected: () => {
        router.push('/login')
        store.commit('CHANGE_LOGIN_STATUS', false)
    },
    onOnline: () => {
        store.commit('CHANGE_NETWORK_STATUS', true)
    },                  // 本机网络连接成功。
    onOffline: () => {
        store.commit('CHANGE_NETWORK_STATUS', false)
    },                 // 本机网络掉线。
    onError: (error) => {
        console.log('on error', error)
        // handleSDKErrorNotifi(error.type, error.message)
    },
})
//fetch 登陆用户的初始数据
const fetchLoginUsersInitData = () => {
    // getMyUserInfos()  //初始必须登录，登录页处理
    fetchFriendList()
    fetchTheLoginUserBlickList()
    fetchGroupList()

    // fetchNewFriends() //获新朋友添加申请--由本地存储
    //初始化vuex中的会话列表相关数据
    store.commit('INIT_CONVERSATION_STATE')
}
//获取登陆用户属性-直接commit，暂不用
const getMyUserInfos = () => {
    const userId = EaseChatClient.user
    store.dispatch('getMyUserInfo', userId)
}
//获取好友列表
const fetchFriendList = () => {
    // const { value = {} } = useLocalStorage('friendList')
    // if (Object.values(JSON.parse(value)).length > 0) return
    store.dispatch('fetchFriendList')
}
//获未处理新朋友申请
const fetchNewFriends = () => {
    store.dispatch('fetchNewFriends')
}
//获取黑名单列表
const fetchTheLoginUserBlickList = () => store.dispatch('fetchBlackList')
//获取加入的群组列表
const fetchGroupList = () => {
    //如果本地存储里不存在群组列表则调用获取群组列表
    // const { value = {} } = useLocalStorage('groupList')
    // if (Object.values(JSON.parse(value)).length > 0) return
    const pageParams = {
        pageNum: 1,
        pageSize: 20,
    }
    store.dispatch('fetchGroupList', pageParams)
}
//在线状态订阅相关
const presenceStatus = (type, user) => {
    type === 'sub' && store.dispatch('subFriendsPresence', [user])
    type === 'unsub' && store.dispatch('unsubFriendsPresence', [user])
}

/* presence 相关监听 */
EaseChatClient.addEventHandler('presenceStatusChange', {
    onPresenceStatusChange: (status) => {
        // // console.log('>>>>>presenceStatusChange', status)
        getUserPresence(...status)
    },

})
//处理登陆用户状态的变更
const getUserPresence = (status) => {
    store.dispatch('handlePresenceChanges', status)
}
/* message 相关监听 */
EaseChatClient.addEventHandler('messageListen', {
    onTextMessage: function (message) {
        // // console.log('>>>>>>>App mesage', message)
        // // console.log('setMessageKey', setMessageKey(message))
        pushNewMessage(message)
    },    // 收到文本消息。
    onEmojiMessage: function (message) {
        pushNewMessage(message)
    },   // 收到表情消息。
    onImageMessage: function (message) {
        pushNewMessage(message)
    },   // 收到图片消息。
    onCmdMessage: function (message) {
        // // console.log('>>>>>收到命令消息', message)
        const {action,ext} = message;
        let group_emchat_id;
        if(ext){
            group_emchat_id = ext.group_emchat_id;
        }
        switch (action) {
            // 好友申请
            case "applyFriend":
                // message信息
                // action"applyFriend"
                // chatType singleChat"
                // ext {}
                // from 20230314_084813_280447"
                // id"1123442676079793768"
                // onlineState  3
                // time 1679136681388
                // to "20230311_154406_815844"
                // typecmd"

                // const {from,to,id} = message
                // const informContent ={
                //     from,
                //     status: '',
                //     nickname: "？？",
                //     user_id: "??",
                //     to,
                //     id,
                //     type: "subscribe",
                // }
                // submitInformData(INFORM_FROM.FRIEND, informContent)

                // 直接接口更新
                fetchNewFriends();
                break;
            // 申请好友同意
            case "doHandleApply":
                fetchFriendList();
                break;
            //创建群，退出群，删除群
            case "addGroup":  
            case "quitGroup":
            case "delGroup":
                fetchGroupList();
                break;
            case "editGroup":
                fetchGroupList();
                store.dispatch('getAssignGroupDetail', group_emchat_id)
                break;
            case "totalGag":
                store.dispatch('getAssignGroupDetail', group_emchat_id)
                break;
            default:
                break;
        }
    },     // 收到命令消息。
    onAudioMessage: function (message) {
        pushNewMessage(message)
    },   // 收到音频消息。
    onLocationMessage: function (message) {
        pushNewMessage(message)
    },// 收到位置消息。
    onFileMessage: function (message) {
        pushNewMessage(message)
    },    // 收到文件消息。
    onCustomMessage: function (message) {
        pushNewMessage(message)
    },  // 收到自定义消息。
    onVideoMessage: function (message) {
        pushNewMessage(message)
    },     // 收到视频消息。
    onRecallMessage: function (message) {
        otherRecallMessage(message)
    },    // 收到消息撤回回执。
})
//接收的消息往store中push
const pushNewMessage = (message) => {
    store.dispatch('createNewMessage', message)
}
//收到他人的撤回指令
const otherRecallMessage = (message) => {
    // // console.log('>>>>>收到他人撤回', message)
    const { from, to, mid } = message
    //单对单的撤回to必然为登陆的用户id，群组发起撤回to必然为群组id 所以key可以这样来区分群组或者单人。
    const key = to === EaseChatClient.user ? from : to
    // // console.log('>>>>>收到他人撤回', key)
    store.commit('CHANGE_MESSAGE_BODAY', { type: 'recall', key, mid })
    store.dispatch('gatherConversation', key)
}
/* 好友关系相关监听 */
const { INFORM_FROM } = informType
// friendListen 弃用
EaseChatClient.addEventHandler('friendListen', {
    // 收到好友邀请触发此方法。
    onContactInvited: (data) => {
        //写入INFORM
        // // console.log('>>>>>>收到好友申请', data)
        // from: "3a5fb7c1a3"
        // status: ""
        // to: "0df7a3f315"
        // type: "subscribe"
        submitInformData(INFORM_FROM.FRIEND, data)
    },
    // 联系人被删除时触发此方法。
    onContactDeleted: (data) => {
        //写入INFORM
        // // console.log('>>>>收到好友关系解散applyFriend', data)
        submitInformData(INFORM_FROM.FRIEND, data)
        //取消针对好友的在线状态订阅
        presenceStatus('unsub', data.from)
        //好友关系解除重新获取好友列表
        fetchFriendList()
    },
    // 新增联系人会触发此方法。
    onContactAdded: (data) => {
        // // console.log('>>>>好友新增监听', data)
        submitInformData(INFORM_FROM.FRIEND, data)
        //新增好友重新获取好友列表
        fetchFriendList()

    },
    // 好友请求被拒绝时触发此方法。
    onContactRefuse: (data) => {
        //写入INFORM
        // // console.log('>>>>>>好友申请被拒绝', data)
        data.type = 'other_person_refuse'
        submitInformData(INFORM_FROM.FRIEND, data)
    },
    // 好友请求被同意时触发此方法。
    onContactAgreed: (data) => {
        //写入INFORM
        // // console.log('>>>>>对方同意了好友申请', data)
        //改掉data中的type
        data.type = 'other_person_agree'
        submitInformData(INFORM_FROM.FRIEND, data)
        //对方同意后重新获取好友列表
        fetchFriendList()
    }
})
/* 群组相关监听 */
// EaseChatClient.addEventHandler('groupEvent', {
//     onGroupEvent: (groupevent) => {
//         // console.log('>>>>>>>收到群组事件', groupevent)
//         submitInformData(INFORM_FROM.GROUP, groupevent)
//     }
// })

const submitInformData = (fromType, informContent) => {
    // // console.log('>>>submitInformData>>>', fromType, informContent)
    store.dispatch('createNewInform', { fromType, informContent })

}

/* 重新登陆 */
//读取本地EASEIM_loginUser
const EASEIM_loginUser = window.localStorage.getItem('EASEIM_loginUser')
const loginUserFromStorage = JSON.parse(EASEIM_loginUser) || {}
const handleRelogin = () => {
    // // console.log('重新登陆',loginUserFromStorage.user,loginUserFromStorage.accessToken)
    EaseChatClient.open({
        user: loginUserFromStorage.user,
        accessToken: loginUserFromStorage.accessToken
    })

}
if (loginUserFromStorage?.user && loginUserFromStorage?.accessToken) {
    handleRelogin()
}
/* EaseCallKit 相关 */
const easeCallKit = ref(null)
const inviteCallComp = ref(null)
//多人会议使用-弹出邀请模态框
const showModal = ({ groupId }) => {
    // // console.log('可以弹出邀请框', groupId)
    inviteCallComp.value.alertDialog(groupId)
}
//多人会议使用-传递给邀请组件发送邀请消息
const sendMulitInviteMsg = (targetIMId) => {
    // // console.log('targetIMIdtargetIMIdtargetIMId', targetIMId)
    const callType = 2
    easeCallKit.value.inMultiChanelSendInviteMsg(targetIMId, callType)
}

</script>
<template>
    <router-view v-slot="{ Component }">
        <transition name="slide-fade" mode="out-in" :duration="{ enter: 500, leave: 300 }">
            <component :is="Component" />
        </transition>
    </router-view>
    <!-- 铃声标签 -->
    <audio id="ring" :src="ring" controls hidden></audio>
    <!-- About EaseCallKit -->
    <EaseCallKit ref="easeCallKit" :EaseIMClient="EaseChatClient" :msgCreateFunc="EaseChatSDK.message"
        @onInviteMembers="showModal" />
    <InviteCallMembers ref="inviteCallComp" @sendMulitInviteMsg="sendMulitInviteMsg" />
</template>

<style type="scss">
@import './styles/reset/reset.css';
@import './styles/iconfont/iconfont.css';
@import './styles/iconfont/zicon.css';

/* .slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0.3;
}

.slide-fade-enter-to,
.slide-fade-leave-from {
  opacity: 1;
} */
</style>
