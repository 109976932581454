import request from '@/utils/request'

// 获好友
export function getUserFriendList(params={}) {
    return request({
        url: '/Api/FriendsApi/getUserFriendList',
        method: 'post',
        data: params,
    })
}
// 删除好友
export function delFriends(params={}) {
    return request({
        url: '/Api/FriendsApi/delFriends',
        method: 'post',
        data: params,
    })
}
// 添加好友
export function submitApply(params={}) {
    return request({
        url: '/Api/FriendsApi/submitApply',
        method: 'post',
        data: params,
    })
}
// 新朋友列表，待处理
export function getNewFriendList(params={}) {
    return request({
        url: '/Api/FriendsApi/getNewFriendList',
        method: 'post',
        data: params,
    })
}
// 同意拒绝
export function doHandleApply(params={}) {
    return request({
        url: '/Api/FriendsApi/doHandleApply',
        method: 'post',
        data: params,
    })
}

// 获群组
export function myGroups(params={}) {
    return request({
        url: '/Api/Group/myGroups',
        method: 'post',
        data: params,
    })
}