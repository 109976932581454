import Cookies from 'js-cookie'

import {Encrypt,Decrypt} from './secret'
const TokenKey = 'Admin-Token'

export function getToken() {
  let temp =Cookies.get(TokenKey);
  return temp ? Decrypt(temp) : '';
}
export function setToken(token) {
  return Cookies.set(TokenKey, Encrypt(token))
}
export function removeToken() {
  return Cookies.remove(TokenKey)
}
